import React from 'react';
import * as d3 from 'd3';

export const useD3ProductionNetwork = (renderChartFn, dependencies) => {

    const refNetwork = React.useRef(null);
    const refBeeswarm = React.useRef(null);

    //const interval_obj = {interval_id: null};

    React.useEffect(() => {
        renderChartFn(d3.select(refNetwork.current),
                      d3.select(refBeeswarm.current));

        /* Return a cleanup function that clears everything out
           of the svg elements and clears the interval that
           updates the visualization */
        return () => {

          // Clear the interval
          //clearInterval(interval_obj.interval_id);

          // Clear out everything in the svg elements
          d3.select(refNetwork.current).selectAll("*").remove();
          d3.select(refBeeswarm.current).selectAll("*").remove();

        };

      }, dependencies);

    return [refNetwork, refBeeswarm];

}
