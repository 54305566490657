import React from 'react';
import * as L from "leaflet";
import * as d3 from 'd3';

export const useD3Leaflet = (renderChartFn, dependencies, useMap) => {

  /* Declare a variable that will hold the Leaflet map object
     if we're using a map */
  var map = null;

  /* Declare a ref that will point to the SVG container for
     the treemap */
  const ref = React.useRef(null);

  React.useEffect(() => {

    // Create the Leaflet map if useMap is true
    if(useMap) {
      map = L.map('map', {
        zoomControl: false,
        boxZoom: false,
        doubleClickZoom: false,
        dragging: true,
        scrollWheelZoom: false
      }).setView([37.8, -96], 3);
    }

    // Call the render function passing the SVG ref,
    // the map object, and the useMap flag
    renderChartFn(d3.select(ref.current),
                  map,
                  useMap);

    /* Return a cleanup function that removes the map and
       clears everything out of the SVG element */
    return () => {

      // Remove the map if useMap is true
      if(useMap) {
        map.remove();
      }

      // Clear out everything in the svg element
      d3.select(ref.current).selectAll("*").remove();

    };

  }, dependencies);

  // Return the ref
  return ref;

}
