import React from 'react';
import * as d3 from 'd3';

export const useD3 = (renderChartFn, dependencies) => {

    const ref = React.useRef();

    const interval_obj = {interval_id: null};

    React.useEffect(() => {
        renderChartFn(d3.select(ref.current),
                      interval_obj);

        /* Return a cleanup function that clears everything out
           of the svg elements and clears the interval that
           updates the visualization */
        return () => {

          // Clear the interval
          clearInterval(interval_obj.interval_id);

          // Clear out everything in the svg element
          d3.select(ref.current).selectAll("*").remove();

        };

      }, dependencies);

    return ref;

}
