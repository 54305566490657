import React from 'react';
import * as L from "leaflet";
import './industry_output_map.css';
import 'leaflet/dist/leaflet.css';
import { useD3Leaflet } from './useD3Leaflet';
import statesOutlines from "./states_outlines.json";
import * as d3 from 'd3';
import productionData from "./production_data.csv";

function IndustryOutputMap({ index, useMap }) {

  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ref = useD3Leaflet(
    (svg, map, useMap) => {

      /* Set the visualization margins and calculate the width
         and height of the visualization taking account
         of the margins */
      var margin = {top: 20, right: 0, bottom: 0, left: 0},
          width = +svg.attr("width") - margin.left - margin.right,
          height = +svg.attr("height") - margin.top - margin.bottom;

      // Set the current zoom to "United States"
      var current_geography = "United States";

      var treemap_area = svg.append("g")
        .attr("transform", "translate(" + 0 + "," + margin.top + ")");

      var industry_label = svg.append("text")
        .attr("class", "treemap_title")
        .attr("transform", "translate(" + 7 + "," + 12 + ")")
        .text("All Industries");

      // Define ordinal scale for the colors in the treemap
      var color;

      function style(feature) {
          // Extract the 2-digit NAICS rows just for this state
          let filtered_data = full_data.filter((d) => (d.geography === feature.properties.NAME) && (d.parent === "universal"));

          /* Get the industry code of the highest producer and the
             industry code of the highest producer excluding the
             industry "52,53" (finance, insurance, real estate,
             rental, and leasing) */
          let first = filtered_data[0].first;
          let second = filtered_data[0].second;

          return {
              fillColor: color(first === "52,53" ? second : first),
              weight: 1,
              opacity: 1,
              color: '#aaa',
              fillOpacity: 0.7
          };
      }

      function highlightFeature(e) {
        var layer = e.target;

        layer.setStyle({
            weight: 2,
            color: '#555',
            fillOpacity: 0.8
        });

        layer.bringToFront();
      }

      function resetHighlight(e) {
        geojson.resetStyle(e.target);
      }

      function zoomToFeature(e) {
        var layer = e.target;
        var geography_name = layer.feature.properties.NAME;

        if(geography_name === current_geography) {
          current_geography = "United States";
          map.setView([37.8, -96], 3);
          info.update();
          production_data = full_data.filter((d) => (d.geography === "United States" || d.industry === "universal"));
        }
        else {
          current_geography = geography_name;
          map.fitBounds(e.target.getBounds());
          info.update(geography_name);
          production_data = full_data.filter((d) => (d.geography === geography_name || d.industry === "universal"));
        }

        treemap_zoomed = false;
        industry_label.text("All Industries");

        // Draw the visualization
        draw_treemap();
      }

      function onEachFeature(feature, layer) {
        layer.on({
            mouseover: highlightFeature,
            mouseout: resetHighlight,
            click: zoomToFeature
        });
      }

      var geojson;
      var info;
      var full_data;
      var production_data;
      d3.csv(productionData).then(function(loaded_data) {

          full_data = loaded_data;

          color = d3.scaleOrdinal(full_data.filter((d) => (d.geography === "United States" && d.parent === "universal")).map(d => d.industry).sort(),
            ["#b2e061",    // Agriculture, forestry, fishing and hunting 11
             "#aaa",       // Mining, quarrying, and oil and gas extraction 21
             "#aaa",       // Utilities 22
             "#7eb0d5",    // Construction 23
             "#8bd3c7",    // Manufacturing 31-33
             "#bd7ebe",    // Wholesale trade 42
             "#bd7ebe",    // Retail trade 44-45
             "#bd7ebe",    // Transportation and warehousing 48-49
             "#ffb55a",    // Information 51
             "#ffb55a",    // 52,53 Finance, insurance, real estate, rental, and leasing
             "#ffee65",    // 54,55,56 Professional and business services
             "#beb9db",    // 61,62 Educational services, health care, and social assistance
             "#fd7f6f",    // 71,72 Arts, entertainment, recreation, accommodation, and food services
             "#777",       // 81 Other services (except government and government enterprises
             "#fdcce5",    // 92 Government and government enterprises
             "black"]);    // universal

          /* Extract either the upstream or downtsream values
             from the dataset */
          production_data = loaded_data.filter((d) => (d.geography === "United States" || d.industry === "universal"));

          // Draw the visualization
          draw_treemap();

          if(useMap) {
            // Load background tiles from OpenStreetMap
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
              maxZoom: 19,
              attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(map);

            geojson = L.geoJson(statesOutlines, {
              style: style,
              interactive: true,
              onEachFeature: onEachFeature,
              attribution: '&copy; <a href="https://www.census.gov/geographies/mapping-files/time-series/geo/carto-boundary-file.html">U.S. Census Bureau</a>'
            }).addTo(map);

            // Create the control
            info = L.control();

            // Set function to create label
            info.onAdd = function (map) {
              this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
              this.update();
              return this._div;
            };

            // Set function to use in updating label
            info.update = function (props) {
                this._div.innerHTML = '<h4>State Focus</h4>' +  (props ?
                    '<b>' + props + '</b>' : '[Select a state]');
            };

            // Add control to map
            info.addTo(map);
          }

      });

      var data_to_bind;
      var treemap_zoomed = false;

      // Create the scales
      const x = d3.scaleLinear().domain([0,width]).rangeRound([0, width]);
      const y = d3.scaleLinear().domain([0,height]).rangeRound([0, height]);



      function draw_treemap() {

        // Define a transition
        const t = svg.transition().duration(550);

        // Create a formatter that places commas
        const format = d3.format(",d");

        // Stratify data
        var root = d3.stratify()
          .id(function(d) { return d.industry; })
          .parentId(function(d) { return d.parent; })
          (production_data);

        // Sum
        root.sum(d => (production_data.map(d => d.parent).includes(d.industry) ? 0 : +d.value))
            .sort((a, b) => b.value - a.value);


        if(!treemap_zoomed) {
          data_to_bind = root;
          x.domain([0, width]);
          y.domain([0, height]);
        }

        // Create treemap
        d3.treemap()
          .size([width, height])
          .paddingInner(2)
          .paddingOuter(0)
          .round(true)
          (root);

        // Create the treemap
        treemap_area.selectAll("g")
          .data(data_to_bind.children, d => d.data.industry)
          .join(
            enter => {
              const new_leaf =
                enter.append("g")
                  .attr("class", "treemap_group")
                  .attr("transform", d => `translate(${x(d.x0)},${y(d.y0)})`)
                  .attr("opacity", 0)
                  .on("click", (e,d) => {
                    if(!treemap_zoomed && d.children !== undefined) {
                      data_to_bind = d;
                      treemap_zoomed = true;
                      x.domain([d.x0, d.x1]);
                      y.domain([d.y0, d.y1]);
                      industry_label.text(d.data.industry_desc);
                      draw_treemap();
                    } else {
                      data_to_bind = root;
                      treemap_zoomed = false;
                      industry_label.text("All Industries");
                      draw_treemap();
                    }
                  });

              new_leaf
                 .append("rect")
                   .attr("id", d => "rect_id_" + index + "_" + d.data.industry)
                   .attr("class", "treemap_rect")
                   .attr('width', function (d) { return x(d.x1) - x(d.x0); })
                   .attr('height', function (d) { return y(d.y1) - y(d.y0); })
                   .attr("stroke", "#555")
                   .attr("stroke-width", 0)
                   .attr("fill", d => {
                     return d.data.parent === "universal" ? color(d.data.industry) : color(d.data.parent) })
                   .attr("rx", 3)
                   .attr("ry", 3);

               new_leaf.append("clipPath")
                 .attr("id", d => "clippath_id_" + index + "_" + d.data.industry)
                 .append("use")
                   .attr("xlink:href", d => "#rect_id_" + index + "_" + d.data.industry);

               new_leaf.append("text")
                 .attr("class", "treemap_label")
                 .attr("clip-path", d => "url(#clippath_id_" + index + "_" + d.data.industry + ")")
                 .attr("x", 7)
                 .attr("y", 17)
                   .text(d => d.data.industry_desc)
                 .style("font-weight", d => d.children !== undefined ? "bold" : "normal");

               new_leaf.append("text")
                 .attr("class", "treemap_value")
                 .attr("clip-path", d => "url(#clippath_id_" + index + "_" + d.data.industry + ")")
                 .attr("x", 7)
                 .attr("y", 34)
                   .text(d => "$" + format(d.data.value) + " (" + d.data.percent + "%)");
            },
            update => {
              update.transition(t)
                .attr("transform", d => `translate(${d.x0},${d.y0})`);

              update.select("rect").transition(t)
              .attr('width', function (d) { return d.x1 - d.x0; })
              .attr('height', function (d) { return d.y1 - d.y0; });

              update.select("text:nth-child(4)")
              .text(d => "$" + format(d.data.value) + " (" + d.data.percent + "%)");
            },
            exit => exit.remove()
          );

          treemap_area.selectAll("g").transition(t)
            .attr("opacity", 1);

      }

    },
    [windowSize.width],
    useMap
  );

  return (
    <div className="svg_and_map">
      {useMap ? <div id="map" style={{width: windowSize.width >= 1300 ? windowSize.width * 0.21 : windowSize.width * 0.8,
                                      height: windowSize.width >= 1300 ? 550 : 300}}></div>
              : null}
      <svg className="svg_treemap" ref={ref} width={windowSize.width >= 1300 ?
                                                      (useMap ? windowSize.width * 0.27 : windowSize.width * 0.5) :
                                                      (windowSize.width * 0.8)} height="550"></svg>

    </div>
  );
}

export default IndustryOutputMap;
